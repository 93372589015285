@import "./_stylesConfig.scss";
.rs-picker-select-menu{
  margin-top: -37px;
  .ReactVirtualized__List{
    height: 200px!important;
  }
}
.App {
  height: 100%;
  background-color: $dashboard-background;
  color: $dark-txt-color;

  .rs-checkbox-checked .rs-checkbox-wrapper .rs-checkbox-inner::before {
    background-color: $gradient-color-1;
    border-color: $gradient-color-1;
  }

  .rs-picker-has-value .rs-btn .rs-picker-toggle-value, .rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
    color: #313130
  }

  .rs-calendar-panel .rs-calendar-table-cell-is-today .rs-calendar-table-cell-day {
    background-color: $gradient-color-2
  }

  .rs-calendar-panel .rs-calendar-table-cell-selected .rs-calendar-table-cell-content {
    border: 1px solid $gradient-color-2
  }

  // Widgetul de support
  .rcw-widget-container {
    display: none;
  }


  .button:not(.rs-btn-ghost) {
    color: $light-txt-color;
    border: none;
    border-radius: 30px;
  }
  .btn-primary {
    @include button-gradient;
  }

  .btn-outline-primary {
    @include button-transparent;
  }

  .pagination-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem 0;

    .page-status {
      font-weight: bold;
      color: $gradient-color-2;
      font-size: 1rem;
      margin: 0 1rem;
    }

    .pagination-btns {
      width: 35px;
      height: 35px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .loading-container{
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;

    background: rgba(0,0,0,0.4);
    display: flex;
    justify-content: center;
    vertical-align: center;
    margin: auto;

    .loading-loader-container{
      width: 100vw;
      justify-content: center;
      display: flex;
    }
    .loading-message-text{
      display: flex;
      justify-content: center;
      color: $gradient-color-2;
      font-weight: bold;
      font-size: 1.3rem;
      text-align: center;
    }
  }
  .loading-message-container {
    //display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 2rem;

    .loading-message-loader{
      display: flex;
      width: 100vw;
      justify-content: center;
    }
    .loading-message{
      display: flex;
      //width: 100vw;
      justify-content: center;
      color: $gradient-color-2;
      font-weight: bold;
      font-size: 1.3rem;
      text-align: center;
    }

  }

  .rs-btn-toggle-checked {
    @include tinker-gradient;
  }
}

//responsive modals
@media only screen and (max-width: 800px) {
  .rs-modal-dialog {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .rs-modal {
    width: 100% !important;
  }
}

@media only screen and (max-width: 400px) {
  .rs-modal-body {
    max-height: none !important;
  }
  .rs-nav-tabs {
    ul {
      display: flex;
      flex-wrap: wrap;
    }
    .rs-nav-item-content {
      font-size: 12px !important;
    }
  }
}
// modal-responsive-end

//fonts

//46
.font-size-46 {
  font-size: 46px;
}

@media only screen and (max-width: 991px) {
  .font-size-46 {
    font-size: 28px;
  }
}

@media only screen and (max-width: 680px) {
  .font-size-46 {
    font-size: 22px;
  }
}

//22
.font-size-22 {
  font-size: 22px;
}

@media only screen and (max-width: 991px) {
  .font-size-22 {
    font-size: 16px;
  }
}

@media only screen and (max-width: 680px) {
  .font-size-22 {
    font-size: 14px;
  }
}

//18
.font-size-20 {
  font-size: 14px;
}

@media only screen and (max-width: 991px) {
  .font-size-20 {
    font-size: 14px;
  }
}

@media only screen and (max-width: 680px) {
  .font-size-20 {
    font-size: 14px;
  }
}

@media only screen and (max-width: 400px) {
  .font-size-20 {
    font-size: 12px;
  }
}

//14
.font-size-14 {
  font-size: 14px;
}

@media only screen and (max-width: 400px) {
  .font-size-14 {
    font-size: 12px;
    word-break: break-word;
  }
}

// padding for titles
@media screen and (min-width: 681px) {
  .main-title {
    padding-top: 1.7rem;
    padding-bottom: 1rem;
  }
}

// max-height-modal
.rs-modal-body {
  max-height: unset !important;
}

.input-invalid {
  border: 1px solid red !important;

}

.input-invalid-icon {
  position:absolute;
  right: 0;
  z-index: 10;
  padding: 7px 11px;
  font-size:16px;
  color:red;
}

.input-valid-icon {
  position:absolute;
  right: 0;
  z-index: 10;
  padding: 7px 11px;
  font-size:16px;
  color:green;
}

.input-show-pass-icon {
  position:absolute;
  right: 20px;
  z-index: 13;
  padding: 7px 11px;
  font-size:16px;
  color:gray;
}

.modal-buttons-cont {
  margin-top: 2rem;
  width:100%;
  text-align: center;
}

.modal-buttons-cont button:first-of-type {
  margin-right: 1rem;
}

.alert {
  width: 100%;
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border: 1px solid #ffeeba;
}
.alert-info {
  color: #856404;
  background-color: #fff3cd;
  border: 1px solid #ffeeba;
}

.mt-2 {
  margin-top: 20px;
}

.rs-picker-select-menu-item.rs-picker-select-menu-item-active, .rs-picker-select-menu-item.rs-picker-select-menu-item-active:hover {
  color: $gradient-color-2 !important;
  background-color: rgba(231, 158, 49, 0.14) !important;
}

.rs-picker-default:not(.rs-picker-disabled) .rs-btn:hover, .rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:hover, .rs-picker-default:not(.rs-picker-disabled) .rs-btn.active, .rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle.active {
  border-color: $gradient-color-2 !important;
}

.rs-input-group:not(.rs-input-group-inside):not(.rs-input-group-disabled):hover, .rs-input-group:not(.rs-input-group-inside):not(.rs-input-group-disabled).rs-input-group-focus {
  border-color:  #333333 !important;
}

.modal-body {
  .loading-message-container {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 2rem;

    .loading-message {
      color: $gradient-color-2;
      font-weight: bold;
      font-size: 1.3rem;
    }
  }
}
