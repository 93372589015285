@import "../../stylesConfig";

.update-psw-page {
  display: flex;
  flex-direction: column;
  height: 90vh;
  padding: 2rem;

  .update-psw-logo-container {
    display: flex;
    justify-content: center;
    margin-bottom: 2rem;

    .update-psw-logo {
      width: 150px;
      height: auto;
    }
  }

  .update-psw-content-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;

    h1 {
      // font-weight: bolder;
      font-size: 2rem;
      text-align: center;
    }
  }

  .update-psw-form-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .update-psw-input-group {
      margin-top: 2rem;
      border: 1px solid $gradient-color-2;

      .rs-input-group-addon {
        background-image: linear-gradient($gradient-color-1, $gradient-color-2);
        color: $light-txt-color;
      }

      // input {
      //   font-size: 1.2rem;
      // }
    }
  }

  .update-psw-btn-container {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 3rem;

    .update-psw-btn {
      padding: 0.6rem 0;
      border: none;
      background-image: linear-gradient($gradient-color-1, $gradient-color-2);
      color: $light-txt-color;
      text-transform: uppercase;
      font-weight: bold;
      border-radius: 50px;
      // width: 100vw;
      width: 200px;
    }

    .update-psw-btn:hover {
      background-image: linear-gradient($gradient-color-2, $gradient-color-1);
    }
  }
}

// Media Querries
@media screen and (max-width: 520px) {
  .update-psw-page {
    .update-psw-content-container {
      h1 {
        font-size: 1.7rem;
      }
    }

    // .update-psw-btn-container {
    //   .update-psw-btn {
    //     padding: 1rem 0;
    //   }
    // }
  }
}

@media screen and (min-width: 620px) {
  .update-psw-page {
    .update-psw-form-container {
      .update-psw-input-group {
        width: 50vw;
      }
    }
  }
}

// @media screen and (min-width: 768px) {
//   .update-psw-page {
//     .update-psw-form-container {
//       .update-psw-input-group {
//         width: 70vw;
//       }
//     }
//     // .update-psw-btn-container {
//     //   .update-psw-btn {
//     //     width: 70vw;
//     //   }
//     // }
//   }
// }

@media screen and (min-width: 980px) {
  .update-psw-page {
    .update-psw-form-container {
      .update-psw-input-group {
        width: 30vw;
      }
    }
    // .update-psw-btn-container {
    //   .update-psw-btn {
    //     width: 50vw;
    //   }
    // }
  }
}

// @media screen and (min-width: 1800px) {
//   .update-psw-page {
//     .update-psw-form-container {
//       .update-psw-input-group {
//         width: 30vw;
//       }
//     }
//     // .update-psw-btn-container {
//     //   .update-psw-btn {
//     //     width: 30vw;
//     //   }
//     // }
//   }
// }
