* {
  font-family: "Nunito", sans-serif;
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@media only screen and (max-width: 600px) {
  .hide-on-mobile {
    display: none;
  }
}
