// COLORS

// gradients
$gradient-color-1: #f8bb2c;
$gradient-color-4: #e79e31;
$gradient-color-red-1: #d12f1d;
$gradient-color-red-2: #b3200c;

$gradient-color-3: #f8bb2c;
$gradient-color-2: #e79e31;

$light-green-bg: #b5f5ec;
$light-blue-bg: #e4f1f7;
$light-yellow-bg: #fdf1d8;

$success-bg-green: #f3ffe6;
$fail-bg-red: #ffe6e6;

$yellow-color: #f7b643;
$green-color: #1dd1a1;

// text colors
$light-txt-color: #fff;
$dark-txt-color: #49566d;
$red-txt-color: #f97878;
$warning-bg-color: #f8d2d2;

// color succes
$color-succes: #73e600;

//box-shadows
$dashboard-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
$box-shadow-bottom: 0 4px 5px -1px rgba(0, 0, 0, 0.1);

//background-image
$dashboard-background: #f5f6fa;

// box shadows
$shadow-1: 0px 0px 8px 0px rgba(0, 0, 0, 0.3);
$shadow-2: 0px 0px 20px 0px rgba(0, 0, 0, 0.3);

// border color
$border-color: #e7e7e7;

$scrollbar-color: #b4b3b3;

// CONTAINERS
.content-container-col {
  margin-left: auto;
  margin-right: auto;
  width: 1700px;
  max-width: 100%;
  height: 100%;
}

// MIXINS and FUNCTIONS
@mixin tinker-gradient {
  background-image: linear-gradient(
    $gradient-color-1,
    $gradient-color-2
  ) !important;
}

@mixin button-gradient {
  background-image: linear-gradient($gradient-color-1, $gradient-color-2);
  color: $light-txt-color;
  border: none;
  border-radius: 30px;

  &:hover {
    background-image: linear-gradient($gradient-color-2, $gradient-color-1);
  }
}
@mixin button-red {
  background-image: linear-gradient($gradient-color-red-1, $gradient-color-red-2);
  color: $light-txt-color;
  border: none;
  border-radius: 30px;

  &:hover {
    background-image: linear-gradient($gradient-color-red-1, $gradient-color-red-2);
  }
}

@mixin button-transparent {
  background-color: transparent;
  border: 1px solid $gradient-color-2;
  border-radius: 30px;
  color: $gradient-color-2;

  &:hover {
    background-color: $gradient-color-2 !important;
    color: $light-txt-color;
  }
}

@mixin button-white {
  background-color: $light-txt-color;
  border: 1px solid $gradient-color-2;
  border-radius: 30px;
  color: $gradient-color-2;

  &:hover {
    background-color: $gradient-color-2;
    color: $light-txt-color;
  }
}

@mixin scrollbar {
  &::-webkit-scrollbar {
    width: 0.5rem;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: $scrollbar-color;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: darken($color: $scrollbar-color, $amount: 10%);
  }
}

@mixin horizontal-scrollbar {
  &::-webkit-scrollbar {
    height: 0.8rem;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: $scrollbar-color;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: darken($color: $scrollbar-color, $amount: 10%);
  }
}
